import axios from 'axios';
import { parse, stringify } from 'qs';
import '@/bootstrap';
import { ElMessage, ElMessageBox } from 'element-plus';
// import { isLogged, setLogged } from '@/utils/auth';

// Create axios instance
const service = axios.create({
  baseURL: import.meta.env.VITE_BASE_API,
  timeout: 10000,
  paramsSerializer: {
    encode: parse,
    serialize: stringify,
  },
});

// Request intercepter
service.interceptors.request.use(

  config => {
    // const token = isLogged();
    // if (token) {
    //   config.headers['Authorization'] = 'Bearer ' + isLogged(); // Set JWT token
    // }
    return config;
  },

  error => {
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(

  response => {

    // if (response.headers.authorization) {
    //   setLogged(response.headers.authorization);
    //   response.data.token = response.headers.authorization;
    // }

    if (response.status === 401 || response.status === 403 || response.status === 500) {

      // Try to Re-Login
      ElMessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout',
      {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
      .then(() => {

        // Reset User Token and Reload
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        });

      })

    }

    return response.data;
  },

  error => {

    let message = error.message;

    if (error.response.data && error.response.data.errors) {

      message = error.response.data.message || '';

      let responseError = error.response.data.errors;
      for (const field in responseError) {
        message += responseError[field][0] + '\n';
      }

    } else if (error.response.data && error.response.data.error) {
      message = error.response.data.error;
    }

    ElMessage({
      message: message,
      type: 'error',
      duration: 5 * 1000,
    });

    return Promise.reject(error);

  }
);

export default service;
